.mount-start {
    opacity: 0;
    max-height: 0;
}

.mount-heightAnimation {
    opacity: 1;
    max-height: 80em;
}

.unmount {
    opacity: 0;
    max-height: 0em;
}

.transition-onMount {
    display: block;
    transition: all 1s ease-in-out;
}