.card {
    width: 100%;
    border: 1px solid #DCDCDC;
    position: relative;
    padding: 24px 16px;

    .badge {
        width: fit-content;
        padding: 4px 12px;
        font-size: 16px;
        line-height: 24px;
        background-color: #F2F2F2;
        color: #1175D2;
        font-weight: 700;
        position: absolute;
        right: 16px;
        top: -16px;
    }

    .price {
        font-size: 20px;
    }

    .price-emphasis.price {
        font-size: 32px;
        line-height: 40px;
    }

    &.highlighted-card{
        background-color:#222222;
        color: #ffffff;
        .badge{
            background-color:#1175D2;
            color:#fff;
        }
    }
}

.containertemp{
  max-width:1284px;
  
 
}
@media only screen and (min-width: 768px) {
    .card {
        max-width:380px;
        width:100%;
        }
    
    }

@media only screen and (min-width: 1024px) {
}