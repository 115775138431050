:root {
  --font: 'PT Sans', sans-serif ;
 --bg-transparent:  #f0f8ff00;
}


/*toggle switch*/
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;

}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  
  border: 1px solid black;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 5px;
 
  bottom: 4px;
  background-color: #1e1e1e;
  transition: .4s;
}
input.switch-input:checked + .slider {
  
  border:2px solid #499af1
}

input.switch-input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;

}


input.switch-input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #146ac7;
}

/* Rounded sliders */
.slider.round {
 background-color: white;
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.toggle-switch-div {
  width: 380px;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  padding: 10px 0px 3px 0px;
  
}

.toggle-switch-div>p{
  font-family: var(--font);
  color:black;
  margin-bottom: 1vw;
}

input.switch{
  display:none;
}