.register .container {
    max-width: 480px;
}

.register .container .module-heading{
    text-align:center;
}


.icon{
    padding:3px 5px 0px 4px;
   
}
.inputText-wrapper {
    line-height:24px;
}
.password-rules-div {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 20px;
}
.password-rules-div .password-rules{
    display: flex;
    flex-flow: wrap;
    padding: 4px;
    gap: 5px 10px;
}

    .checked {
        display: -webkit-inline-box;
        flex-direction: row;
        background-image: url(../../UI/Icons/valid-input-icon.svg);
        width: 10.58px;
        height: 8.65px;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px 4px 0px 10px
    }

.unchecked {
    display:-webkit-inline-box;
    background-image: url(../../UI/Icons/invalid-input-icon.svg);
    width: 10.58px;
    padding: 0px 4px 0px 10px;
    height: 8.65px;
    background-repeat: no-repeat;
    background-position: center;
   
}
.bullet-circle {
    display: -webkit-inline-box;
    background-image: url(../../UI/Icons/bullet-icon.svg);
    /* width: 5.58px; */
    padding: 2px;
    margin: 2px;
    height: 1.65px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.password-rule-text{
    margin:0px 5px ;
 
}

/*animation classes*/
