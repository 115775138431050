 .container{
    max-width: 480px ;
}

.checked {
    display:-webkit-inline-box;
    flex-direction:row;
    background-image: url(../../UI/Icons/check-icon.svg);
    width: 10.58px;
   
    height: 8.65px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px 4px 0px 10px
}


/*animation classes*/
p{
    line-height:24px;
}

a:hover{
  cursor:pointer  
}