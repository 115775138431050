.tierchiary-button,
.ghost-button,
.secondary-button,
.primary-button{
   font-size: 1rem;
   padding:18px 34px;
   line-height: 24px;
   border: 0;
   transition: background .3s ease; 
   font-weight: bold;
}

.tierchiary-button.large,
.ghost-button.large,
.secondary-button.large,
.primary-button.large{
   padding:12px 34px;
}
.tierchiary-button.medium,
.ghost-button.medium,
.secondary-button.medium,
.primary-button.medium{
   padding:8px 25px;
}
.tierchiary-button.small,
.ghost-button.small,
.secondary-button.small,
.primary-button.small{
   padding:7px 17px;
}
.secondary-button.large.hasOnlyIcon,
.primary-button.large.hasOnlyIcon{
   padding:0;
   width: 48px;
   height: 48px;
}
.secondary-button.medium.hasOnlyIcon,
.primary-button.medium.hasOnlyIcon{
   padding:0;
   width: 40px;
   height: 40px;
}
.secondary-button.small.hasOnlyIcon,
.primary-button.small.hasOnlyIcon{
   padding:0;
   width: 38px;
   height: 38px;
}

.secondary-button.large.hasIcon .buttonIcon,
.primary-button.large.hasIcon .buttonIcon{
 margin-left: 16px;
}
.secondary-button.medium.hasIcon .buttonIcon,
.primary-button.medium.hasIcon .buttonIcon{
 margin-left: 12px;
}
.secondary-button.small.hasIcon .buttonIcon,
.primary-button.small.hasIcon .buttonIcon{
 margin-left: 10px;
  
}

.secondary-button.large.hasIcon:hover svg path,
.secondary-button.medium.hasIcon:hover svg path,
.secondary-button.small.hasIcon:hover svg path{
   fill: white!important;
}

.secondary-button.large.hasOnlyIcon:hover svg path,
.secondary-button.medium.hasOnlyIcon:hover svg path,
.secondary-button.small.hasOnlyIcon:hover svg path{
   fill: white;
} 

.secondary-button.large.hasIcon.disabled:hover svg path,
.secondary-button.medium.hasIcon.disabled:hover svg path,
.secondary-button.small.hasIcon.disabled:hover svg path{
   fill: #222!important;
}


.secondary-button.large.hasOnlyIcon.disabled:hover svg path,
.secondary-button.medium.hasOnlyIcon.disabled:hover svg path,
.secondary-button.small.hasOnlyIcon.disabled:hover svg path{
   fill: #222!important;
} 