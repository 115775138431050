a.tierchiary-button,
a.ghost-button,
a.secondary-button,
a.primary-button
.tierchiary-button,
.ghost-button,
.secondary-button,
.primary-button {
    background-color: transparent;
    color: #ffffff;
    font-size: 1rem;
    padding: 12px 34px;
    line-height: 24px;
    border: 0;
    transition: background .3s ease;
    font-weight: bold;
    font-size:16px;
}
a.primary-button:hover,
.primary-button:hover{
   background-color: #0a467e;
   cursor: pointer;

} 
a.primary-button.disabled,
 .primary-button.disabled {
        background-color: #9EC6EC;
        cursor: default;
      
    }

    a.secondary-button,
    .secondary-button {
        background-color: #f2f2f2;
        color: #222;
    }
a.secondary-button:hover,
.secondary-button:hover {
    background-color: #222;
    color: #fff;
    cursor: pointer;
}
a.secondary-button.disabled,
.secondary-button.disabled {
    color: #dcdcdc;
    background-color: #f2f2f2;
    cursor: default;
}

a.ghost-button,
.ghost-button {
    font-weight: normal;
    background-color: transparent;
    color: #222;
    border: 1px solid #1f1f1f;
}
    a.ghost-button:hover,
    .ghost-button:hover {
        background-color: #1f1f1f;
        color: #fff;
        cursor: pointer;
    }
    a.ghost-button.disabled
    .ghost-button.disabled {
        color: #9A9A9A;
        border-color: #9A9A9A;
        cursor: default;
    }

.ghost-button.disabled:hover{
   color:#9A9A9A;
   border-color: #9A9A9A;
   background-color: transparent;
   cursor: default; 
}
a.tierchiary-button,
.tierchiary-button {
    font-weight: normal;
    background-color: transparent;
    color: #222;
}
a.tierchiary-button:hover,
.tierchiary-button:hover {
    background-color: #f7f7f7;
    color: #A5A5A5;
    cursor: pointer;
}
    a.tierchiary-button.disabled,
    .tierchiary-button.disabled {
        color: #A5A5A5;
        cursor: default;
    }
        a.tierchiary-button.disabled:hover,
        .tierchiary-button.disabled:hover {
            color: #A5A5A5;
            background-color: transparent;
            cursor: default;
        }
.field{
   margin:20px;
}
 
    a.tierchiary-button.full-width,
    a.ghost-button.full-width,
    a.secondary-button.full-width,
    a.primary-button.full-width
    .tierchiary-button.full-width,
    .ghost-button.full-width,
    .secondary-button.full-width,
    .primary-button.full-width {
        width: 100%;
    }
    a.tierchiary-button.large, a.ghost-button.large, a.secondary-button.large, a.primary-button.large,
    .tierchiary-button.large, .ghost-button.large, .secondary-button.large, .primary-button.large{
   padding:12px 34px;
}
    a.tierchiary-button.medium,
    a.ghost-button.medium,
    a.secondary-button.medium,
    a.primary-button.medium,
    .tierchiary-button.medium,
    .ghost-button.medium,
    .secondary-button.medium,
    .primary-button.medium {
        padding: 8px 25px;
    }
    a.tierchiary-button.small,
    a.ghost-button.small,
    a.secondary-button.small,
    a.primary-button.small,
    .tierchiary-button.small,
    .ghost-button.small,
    .secondary-button.small,
    .primary-button.small {
        padding: 7px 17px;
    }

    a.secondary-button.large.hasOnlyIcon,
    a.primary-button.large.hasOnlyIcon,
    .secondary-button.large.hasOnlyIcon,
    .primary-button.large.hasOnlyIcon {
        padding: 0;
        width: 48px;
        height: 48px;
    }
    a.secondary-button.medium.hasOnlyIcon,
    a.primary-button.medium.hasOnlyIcon,
    .secondary-button.medium.hasOnlyIcon,
    .primary-button.medium.hasOnlyIcon {
        padding: 0;
        width: 40px;
        height: 40px;
    }
    a.secondary-button.small.hasOnlyIcon,
    a.primary-button.small.hasOnlyIcon,
    .secondary-button.small.hasOnlyIcon,
    .primary-button.small.hasOnlyIcon {
        padding: 0;
        width: 38px;
        height: 38px;
    }
    a.secondary-button.large.hasIcon .buttonIcon,
    a.primary-button.large.hasIcon .buttonIcon,
    .secondary-button.large.hasIcon .buttonIcon,
    .primary-button.large.hasIcon .buttonIcon {
        margin-left: 16px;
    }
    a.secondary-button.medium.hasIcon .buttonIcon,
    a.primary-button.medium.hasIcon .buttonIcon,
    .secondary-button.medium.hasIcon .buttonIcon,
    .primary-button.medium.hasIcon .buttonIcon {
        margin-left: 12px;
    }
    a.secondary-button.small.hasIcon .buttonIcon,
    a.primary-button.small.hasIcon .buttonIcon,
    .secondary-button.small.hasIcon .buttonIcon,
    .primary-button.small.hasIcon .buttonIcon {
        margin-left: 10px;
    }

    a.secondary-button.large.hasIcon:hover svg path,
    a.secondary-button.medium.hasIcon:hover svg path,
    a.secondary-button.small.hasIcon:hover svg path,
    .secondary-button.large.hasIcon:hover svg path,
    .secondary-button.medium.hasIcon:hover svg path,
    .secondary-button.small.hasIcon:hover svg path {
        fill: white !important;
    }


    a.secondary-button.large.hasOnlyIcon:hover svg path,
    a.secondary-button.medium.hasOnlyIcon:hover svg path,
    a.secondary-button.small.hasOnlyIcon:hover svg path,
    .secondary-button.large.hasOnlyIcon:hover svg path,
    .secondary-button.medium.hasOnlyIcon:hover svg path,
    .secondary-button.small.hasOnlyIcon:hover svg path {
        fill: white;
    }
   a.secondary-button.large.hasIcon.disabled:hover svg path,
    a.secondary-button.medium.hasIcon.disabled:hover svg path,
    a.secondary-button.small.hasIcon.disabled:hover svg path,
    .secondary-button.large.hasIcon.disabled:hover svg path,
    .secondary-button.medium.hasIcon.disabled:hover svg path,
    .secondary-button.small.hasIcon.disabled:hover svg path {
        fill: #222 !important;
    }

    a.secondary-button.large.hasOnlyIcon.disabled:hover svg path,
    a.secondary-button.medium.hasOnlyIcon.disabled:hover svg path,
    a.secondary-button.small.hasOnlyIcon.disabled:hover svg path,
    .secondary-button.large.hasOnlyIcon.disabled:hover svg path,
    .secondary-button.medium.hasOnlyIcon.disabled:hover svg path,
    .secondary-button.small.hasOnlyIcon.disabled:hover svg path {
        fill: #222 !important;
    } 

    .loading {
    display:-webkit-inline-box;
    flex-direction:row;
    background-image: url(../Icons/load-icon.svg);
    width: 10.58px;
    height: 8.65px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px 4px 0px 10px
}

.updating-icon{
    margin-left:5px;
    margin-bottom:-6px
}
.updating-icon-circle{
    
    position:absolute;
}
.updating-icon-loader{
   transform: rotate(-60deg);
   transform-origin: 50% 50%;
   -webkit-animation: spin 2s linear infinite; /* Safari */
   animation: spin 2s linear infinite;
   position:relative;
}

@media (hover: none) {
    a.primary-button:hover, .primary-button:hover {
        background-color: #0a467e !important;
    }
}