
.forgotten-password .container {
    max-width: 480px;
}

.back-button{
    border-bottom: none; 
    color:#222222;
    line-height: 22px; 
    display: flex;
}

.back-button:hover{
    cursor:pointer;
}

.back-icon {
    display: -webkit-inline-box;
    background-image: url(../../UI/Icons/back-arrow.svg);
    width: 6.06px;
    margin-top: 5px;
    padding: 5px 0 0px 16px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: left;
}