.login .container {
    max-width: 480px;
    min-height: 17em;
}

    .login .container .module-heading {
        text-align: center;
    }

.link-button{
    border: none;
    background-color: transparent;
    padding: 10px 0px 0px 0px;
    line-height: 22px;
    text-decoration: none;
   
  }

  .link-button > p{
    font-weight:700;
 
  }

.link{
    padding: 10px 0px 0px 0px;
    line-height: 22px;
    text-decoration: none;
   
  }
  
  
.policy-message {
    font-size: 0.875rem;
    line-height:1.25rem;
}

/*animation classes*/

.collapsible {
   
    transition: min-height 1s ease-in-out;
    overflow: hidden;
    max-height: 1000px;
    min-height:500px;

}
  .collapsed {
    min-height:300px;
  
  }

.focusableHeader{
  outline:0;
}


.login-transition-before{
  opacity:0;
  max-height: 0;

}

.login-transition-after{
  opacity:1;
  max-height: 40em;

 }

.login-transition{
 display: block;
 transition: all 0.2s ease-in-out ;
 position:relative;
}

.password-block-before{
  display:block;
  width:100%;
  height:0em;
  transition: all 0.2s ease-in-out ;

}

.password-block-after{
  display:block;
  width:100%;
  height:10em;
  transition: all 0.1s ease-in-out ;

}