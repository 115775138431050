.message {
    display: flex;
    align-items: center;
    padding: 12px;

}

.error span.error-icon {
    background-image: url(../Icons/error-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
    flex: 1 0 24px;
}

.warning span.warning-icon {
    background-image: url(../Icons/warning-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}

.neutral span.neutral-icon {
    background-image: url(../Icons/check-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}

.good span.good-icon {
    background-image: url(../Icons/check-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}

.info span.info-icon {
    background-image: url(../Icons/info-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}


.error {
    border: 1px solid #DD2B1B;
    background-color: #FFF9F9;
}

.warning {
    border: 1px solid #F9B446;
    background-color: #FFFBF5;
}

.neutral {
    border: 1px solid Neutral colours/Off white;
    background-color: #FAFAFA;
}

.good {
    border: 1px solid green;
    background-color: #F8FCFA;
}

.info {
    border: 1px solid #1175D2;
    background-color: #F6FAFE;
}