:root {
  --font: 'PT Sans', sans-serif;
  --bg-transparent: #f0f8ff00;
  --blue: #1175D2;
  --grey: #444444;
  --black: #222222;
  --lightGrey: #707070;
}
.item-container {
    margin-bottom: 24px;
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.item-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.sub-item-wrapper {
    margin-top:-8px;
    width:fit-content;
}
/*------------------ flex classes-----------------*/
 
/*------------------------- common classes---------------*/
 
.border-left-2 {
  border-left: 2px solid var(--blue)
}

.border-1 {
  border: 1px solid #DCDCDC;
}

.bg-text-input {
  background-color: #FAFAFA;
 
}

.bg-none {
  background-color: #f0ffff00;
}

.hide-btn {
  border: none;
  background-color: var(--bg-transparent);
}


  
 

/*---(subpart)--checkbox_input_amount-------*/

.checkbox_input_for_amount {
  border: 1px solid #707070;
  padding: 11px 20px 6px 16px;
  width: 180px;
  height: 64px;
}

input[type="checkbox"] {
  background-color: var(--blue);
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
/*--------------------input type="text" and such components css--------------------------*/

.field-wrapper{
    position:relative;
}

.label-text{
  
}
.label {
    font-size:16px;
    width: 100%;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
}

.label-description {
    font-size: 0.875rem;
    line-height: 20px;
    color: #444444;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
}

.label-aid {
    display: flex; 
    line-height:24px;
}
input{
    border-radius:0;
   
}

.div-wrapper{
  display:flex;
  flex-direction:row;
  border: 1px solid #DCDCDC;
  
  background-color: #fafafa;
}

.div-wrapper.small{
  width:45%;
}

.div-wrapper.medium{
  width:60%;
}
.div-wrapper.full-width{
  width:100%;
}

input[type="number"], input[type="text"], input[type="password"], input[type="email"] {
    height: 48px;
    border: 1px solid #fafafa00;
    background-color: #fafafa00;
    padding: 12px;
    font-weight: normal;
    transition: box-shadow 0.12s ease-in;
    font-size: 16px;
}

.field-wrapper > a{
 text-decoration: none;
  position: absolute;
  color:black;
  right: 12px;
  top: 50%;
  transform:translateY(-50%);
  font-size:16px;
  background-color: transparent;
  line-height: 24px;
  cursor: pointer;
}

input[type="text"] + a,
input[type="text"] + button,
input[type="email"] + a,
input[type="email"] + button,
input[type="password"] + button {
    border: 0 solid;
    /* position: absolute;
        right: 12px;
        top: 12px; */
    background-color: transparent;
    line-height: 24px;
    cursor: pointer;
    color: #222;
    padding: 12px;
    font-size: 16px;
}
/*input:placeholder-shown{
  border: 1px solid #ccc;
  border: 1px solid #DCDCDC;
}*/

/*------------------focused-state-------------*/

.div-wrapper{
    transition: box-shadow .2s ease;

}
    .div-wrapper:focus-within {
        -webkit-box-shadow:  0px 0px 0px 2px #1175D2;
        box-shadow: 0px 0px 0px 2px #1175D2;
        border: 1px solid transparent;
    }

    .div-wrapper.filled {
        -webkit-box-shadow: 0px 0px 0px 1px #1175D2;
        box-shadow: 0px 0px 0px 1px #1175D2;
        border: 1px solid transparent;
    }
     
  .div-wrapper.filled:focus-within {
        -webkit-box-shadow: 0px 0px 0px 2px #1175D2;
        box-shadow: 0px 0px 0px 2px #1175D2;
        border: 1px solid transparent;
    }
   
  /* .div-wrapper.filled:focus-within {
      -webkit-box-shadow: inset  0px 0px 0px 2px #1175D2;
      box-shadow: inset 0px 0px 0px 2px #1175D2;
  } */

   .div-wrapper.Invalid-input:focus-within {
        -webkit-box-shadow: 0px 0px 0px 2px #1175D2;
        box-shadow: 0px 0px 0px 2px #1175D2;
        border:1px solid transparent
    } 

    /* .div-wrapper.filled:focus {
        -webkit-box-shadow: 0px 0px 0px 1px #1175D2;
        box-shadow: 0px 0px 0px 1px #1175D2;
        border: 1px solid transparent;
    }
    */
    /* .div-wrapper.Invalid-input:focus-within {
         border:1px solid transparent;
        -webkit-box-shadow: inset 0px 0px 0px 2px #1175D2;
        box-shadow: inset 0px 0px 0px 2px #1175D2;
    } */

input[type="email"]:focus, input[type="text"]:focus, input[type="password"]:focus > {
    outline: none !important;
    -webkit-box-shadow: inset 0px 0px 0 0 #1175D2;
    box-shadow: inset 0px 0px 0px 0 #1175D2;
    border: none !important;
}

input:focus-visible{
  outline:0;
}

/* .div-wrapper > button:focus{
 outline: 2px solid #1175D2 !important;
} */


input[type="email"]:active, input[type="text"]:active, input[type="password"]:active > {
    outline: 0;
    /* -webkit-box-shadow: inset 0px 0px 1px 2px #1175D2;
  box-shadow: inset 0px 0px 1px 2px #1175D2;
   */
    border: none !important;
}


/*------------------------error state--------------------*/
.div-wrapper.Invalid-input {
  background-color: #FFF4F4;
  border: 1px solid #DD2B1B;
  color: black ;
}

input.Invalid-input ::placeholder {
  color: black;
}

/*--------------------filled-state-----------------*/

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: var(--bg-transparent) ;
    border: 0px solid var(--bg-transparent)  ;
    color: #222 !important;
}



input.full-width {
  width: 100%;
  max-width: unset;
  min-width: unset;
}
input.medium {
    width: 100%;
    max-width: unset;
    min-width: unset;
}

input.small {
    width: 100%;
    max-width: unset;
    min-width: unset;
}

input.input_with_button {
  display: flex;
}

.field-container{
  display:flex; 
}

.link {
    padding: 0;
    line-height: 22px;
    text-decoration: none;
  
    line-height: 24px;
    font-weight: 700;
    width: fit-content;
}

    .link:hover {
        text-decoration:none;
    }
    .link:after {
        content: '';
        width: 100%;
        display: block;
        height: 1px;
        background-color: #1175D2;
        margin-top: 3px;
    }
    /* .checkmark{
  height: 24px;
  background-color: white;
  display: block;
  width: 24px;
  border: 1px solid #cbc9c9;
} */
    .text-div {
        position: relative;
        width: 100%;
    }


  

.help-text-icon {
  font-size: 20px;

}

.optional-text {
  font-weight: 500;
  font-family: var(--font);
}

.help-text-btn {
  border-radius: 0;
  border: none;
  padding: 0 0 0 2px;
  background-color: transparent;
}

.help-text-div {
  border-left: 2px solid #1175D2;
}

.help-text-question {
  font-family: var(--font);
  margin-bottom: 0px;
  Line-height: 20px;
  font-style: normal;
}

.help-text-answer {
  font-family: var(--font);
  Line-height: 20px;
  font-style: normal;
  margin-bottom: 0px;

}


.link-button{
  border: none;
  background-color: transparent;
  padding: 10px 0px 0px 0px; 
  line-height: 22px; 
}

.link-button > p{
  margin-bottom: 0px;
  font-family: var(--font);
  font-weight:700;
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
}

input::-ms-reveal { display: none; }