.disabled-account .item-container:last-child .item-wrapper {
    gap: 16px;
    line-height: 24px;

}

.disabled-account .item-container:last-child p:has(a) {
    background-color: #FAFAFA;
    padding: 12px;
}

.disabled-account .item-container:last-child p a {
    color: #222;
    position:relative;
    display:block;
    width:100%;
}
/*
    .disabled-account .item-container:last-child p a:after {
        content: '';
        background-image: url(../UI/Icons/Copy.svg);
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 50%;
        transform:translateY(-50%);

        right: 0;
    }
    */