
/* Provide sufficient contrast against white background */

html {
    font-size: 16px;
}
body {
    background-color: #FaFaFa;
    font-family: 'PT Sans', Arial, sans-serif;
}
*{
    box-sizing:border-box;
}
h1 {
    font-size: 1.75rem;
    line-height: 32px;
}

h2 {
    font-size: 1.375rem;
    line-height: 28px;
}

h3 {
    font-size: 1.25rem;
    line-height: 24px;
}

h4 {
    font-size: 1.125rem;
    line-height: 24px;
}

h5 {
    font-size: 1rem;
    line-height: 24px;
}

a {
    color: #1175D2;
    text-decoration:none;
}
   
a:hover {
 
    text-decoration: underline;
    }

p{
    margin:0;
}

ul {
    line-height: 24px;
    margin: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    padding-inline-start: 20px;
}
header{
    clear:both;
}
 
code {
  color: #E01A76;
}

section {
    margin-bottom: 48px;
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}

.loader {
    border: 2px solid #1175D2;
    border-top: 2px solid #e3e3e3;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.wrapper-content {
    flex: 1;
    display: flex;
    margin: 48px 0;
    align-items: center;
    flex-direction: column;
}

.container {
    background-color: #ffffff;
    border: 1px solid #DCDCDC;
    padding: 20px 16px;
    width: 100%;
    height:fit-content;
}

.item-separator {
    width: 100%;
    height: 1px;
    background-color: #dcdcdc;
}


/* Module Typography */
.module-heading {
    font-size: 1.5rem;
    line-height: 32px;
}

.module-subheading {
    font-size: 1.125rem;
    line-height: 24px;
}

.module-description {
    font-size: 0.875rem;
    line-height: 20px;
}

/*  meta */
.meta {
    font-size: 14px;
    line-height: 20px;
    margin-bottom:8px;
}
.meta p { 
    line-height: 20px;
}

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

.text-between-border {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    position: relative;
    color: #707070;
}

.text-between-buttons {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: bold;
    text-align: center;
    position: relative;
    color: #000000;
}

    .text-between-border:after,
    .text-between-border:before {
        content: '';
        position: absolute;
        width: calc(50% - 16px);
        height: 1px;
        background-color: #DCDCDC;
        display: block;
        top: 12px;
    }

    .text-between-border:before {
        left: 0;
    }

    .text-between-border:after {
        right: 0;
    }
.div-language-selector{
    
}
.img-language-selected{
    border-color : #000000;
    border-width : thin;
    border-style : solid;
}
.errorBox {
    border-width: medium;
    border-style: solid;
    border-block-color:red;
    background-color: #F0AABA;
}


.left-column {
    background-color: #ffffff;
    float: left;
    padding: 10px;
    margin-left: 200px;
    width: 50%;
}
.center-column {
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
   margin:40px 0;
}

.right-column {
    background-color: #ffffff;
    padding: 10px;
    float: right;
    margin-right: 200px;
}
table.basket {
    width: 100%;
}
td.basket-label {
    padding: 5px;
}
td.basket-value {
    padding: 5px;
    text-align: right;
}
div.auth-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
}
div.auth-left {
    flex: 50%;
}
div.auth-content{
    width:100%;
}
div-auth-right
{
    flex:50%;
}

div.url-warning-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

div.url-warning-left {
    content: '';
    min-width: 38px;
    min-height: 38px;
    background-image: url('./css/warning-signs-svgrepo-com.svg');
    flex: 10%;
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
}
div.url-warning-center {
    flex: 80%;
}
div.url-warning-right {
    flex: 10%;
    content: '';
    min-width: 38px;
    min-height: 38px;
    background-image: url('./css/warning-signs-svgrepo-com.svg');
    background-size:contain;
    background-position-x:center;
    background-repeat:no-repeat;
}
span.password-rule-fail {
    color: #DD2B1B;
}
span.password-rule-pass {
    color: green;
}
.noshow
{
    display:none;
}





@media only screen and (min-width: 768px) {
    h1 {
        font-size: 3rem;
        line-height: 56px;
    }

    h2 {
        font-size: 1.5rem;
        line-height: 32px;
    }

    h3 {
        font-size: 1.375rem;
        line-height: 28px;
    }
    .container{
        padding: 32px;
    }
}

@media only screen and (min-width: 1024px) {
    h1 {
        font-size: 2.875rem;
        line-height: 56px;
    }

    h2 {
        font-size: 2rem;
        line-height: 40px;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 32px;
    }

    h4 {
        font-size: 1.25rem;
        line-height: 28px;
    }

    h5 {
        font-size: 1.125rem;
        line-height: 24px;
    }
}
