.error-box {
    border-left: 2px solid #DD2B1B;
    display: flex;
    align-items: center;

    padding-left: 8px;
    height:28px;
}
    .error-box span.error-icon {
        background-image: url(../Icons/error-icon.svg);
        width: 24px;
        display: block;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
    }
.error-box span {
    color: #DD2B1B;
    font-size:14px;
}

.generic-error-box {
    display: flex;
    border: 1px solid #DD2B1B;
    background-color: #FFF4F4;
    padding: 12px;
}
    .generic-error-box.warning {
        display: flex;
        border: 1px solid #F9B446;
        background-color: #FFFBF5;
        padding: 12px;
        align-items: center;
    }
.generic-error-box span{
    color: black
}

.generic-error-box span.generic-error-icon {
    background-image: url(../Icons/generic-error-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}

.universal-error-box{
    background-color: #DD2B1B;
    display: flex;
    padding: 10px;
    text-align: center;
    /* margin-top: -8px; */
    color: white;
    align-items: center;
    justify-content: center;
}

.universal-error-box span.universal-error-icon {
    background-image: url(../Icons/universal-error-icon.svg);
    width: 24px;
    display: block;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
}
