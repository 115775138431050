div.social-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

    div.social-wrapper a {
        text-decoration: none;
        font-weight: bold;
        text-align: center;
        margin-bottom: 16px;
    }
    div.social-wrapper a span{
        display: flex;
        align-items: center;
        justify-content: center;
    }


    div.social-wrapper .google-oauth2 svg,
    div.social-wrapper .facebook svg {
        margin-left: 8px;
    }


    div.social-wrapper a#facebook:hover  span.facebook svg path,
    div.social-wrapper a#google-oauth2:hover  span.google-oauth2 svg path {
        fill: white;
    }

